'use client'
import { useEffect } from 'react'

import {
  RaButton,
  RaContainer,
  RaHeading,
  RaTypography,
} from '@components/core/index.core'
import { dispatchRollbarError } from '@utilities/helpers/dispatchRollbarError.helpers'

import { useSettings } from '@/contexts/settings.context'

const HomeErrorPage = ({ error, reset }) => {
  const { ROLLBAR_TOKEN, ROLLBAR_ENV } = useSettings()
  useEffect(() => {
    dispatchRollbarError(
      `${error?.message} - Digest: ${error?.digest}`,
      ROLLBAR_TOKEN,
      ROLLBAR_ENV
    )
  }, [])

  return (
    <RaContainer>
      <RaHeading component="h2">Error</RaHeading>
      {error?.message && typeof error?.message === 'string' && (
        <RaTypography>{error.message}</RaTypography>
      )}
      {error?.digest && <RaTypography>{error.digest}</RaTypography>}
      <div style={{ maxWidth: 300, marginBottom: 48 }}>
        <RaButton
          label="Try again"
          onClick={reset}
        />
      </div>
    </RaContainer>
  )
}

export default HomeErrorPage
